const OurSolutions = () => {
    const styles = {
        container: {
            padding: "20px",
            textAlign: "center",
            backgroundColor: "#f8f9fa",
        },
        title: {
            fontSize: "28px",
            fontWeight: "bold",
            marginBottom: "10px",
            color: "#333",
        },
        underline: {
            width: "60px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        underline1: {
            width: "50px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        row: {
            display: "flex",
            flexWrap: "wrap", // Allows wrapping to the next line
            justifyContent: "space-around",
            gap: "10px",
            marginTop: "30px",
        },
        card: {
            width: "22%", // Keeps 4 cards in a row
            textAlign: "center",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "10px",
            marginBottom: "20px", // Adds spacing between rows
        },
        image: {
            width: "100%",
            height: "auto",
            borderRadius: "8px",
            marginBottom: "10px",
        },
        cardTitle: {
            fontSize: "18px",
            fontWeight: "bold",
            margin: "10px 0",
            color: "#0199c2",
        },
        cardContent: {
            fontSize: "14px",
            color: "#555",
            lineHeight: "1.6",
            textAlign: "justify",
        },
        button: {
            marginTop: "10px",
            padding: "8px 12px",
            fontSize: "14px",
            color: "#fff",
            backgroundColor: "#f79a19",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
        },
    };

    const items = [
        {
            imgSrc: "solution1.jpg",
            title: "Intelligence Model Governance (IMG)",
            content: "Our first solution is tailored to meet your needs effectively.",
        },
        {
            imgSrc: "solution2.jpg",
            title: "API Security Governance (ASG)",
            content: "Providing innovative approaches to drive success.",
        },
        {
            imgSrc: "solution3.jpg",
            title: "Data Security Governance With Apache Ranger",
            content: "Streamlining processes for better efficiency and results.",
        },
        {
            imgSrc: "solution4.jpg",
            title: "Data Shopping with Google Cloud Platform",
            content: "Empowering businesses with cutting-edge technology.",
        },
        {
            imgSrc: "solution5.jpg",
            title: "Metadata Harvesting connecting to data sources (MIF)",
            content: "Providing innovative approaches to drive success.",
        },
        {
            imgSrc: "solution6.jpg",
            title: "Data Access Control with ServiceNow",
            content: "Streamlining processes for better efficiency and results.",
        },
        {
            imgSrc: "solution7.jpg",
            title: "Data Usage Visibility with version Controller (GIT/Bitbucket)",
            content: "Empowering businesses with cutting-edge technology.",
        },
        {
            imgSrc: "solution8.jpg",
            title: "Data Sets Factory (DSF)",
            content: "Providing innovative approaches to drive success.",
        },
        {
            imgSrc: "solution9.jpg",
            title: "Data Access Control with Trino /Starburst-Apache Ranger",
            content: "Streamlining processes for better efficiency and results.",
        },
        {
            imgSrc: "solution10.jpg",
            title: "Data Privacy and Security Governance",
            content: "Empowering businesses with cutting-edge technology.",
        },
        {
            imgSrc: "solution11.jpg",
            title: "Data analytics/manipulation Governance",
            content: "Empowering businesses with cutting-edge technology.",
        },
        {
            imgSrc: "solution12.jpg",
            title: "Some Key Lorang Inhouse Expertise Solution",
            content: "Empowering businesses with cutting-edge technology.",
        },
    ];

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Our Solutions</h1>
            <div style={styles.underline}></div>
            <div style={styles.underline1}></div>
            <div style={styles.row}>
                {items.map((item, index) => (
                    <div key={index} style={styles.card}>
                        <img
                            src={item.imgSrc}
                            alt={item.title}
                            style={styles.image}
                        />
                        <h2 style={styles.cardTitle}>{item.title}</h2>
                        <p style={styles.cardContent}>{item.content}</p>
                        <button style={styles.button}>Read More</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurSolutions;
