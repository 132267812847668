import Image1 from './img/Datanomist Inital Days.jpg';
import Image2 from './img/DSNR_Office Opening.jpg';
import Image3 from './img/Mohan Photo Collagin_Datanomist_IND2.jpg';
import Image4 from './img/Mohan Photo Collagin_Datanomist.jpg';
import Image5 from './img/Mohan Photo Collagin1.jpg';

const Gallery = () => {
    const styles = {
        container: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
            padding: "20px",
            backgroundColor: "#f8f9fa",
        },
        image: {
            width: "30%",
            maxWidth: "300px",
            height: "auto",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            objectFit: "cover",
        },
        underline: {
            width: "60px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        underline1: {
            width: "50px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        title: {
            textAlign: "center",
            fontSize: "28px",
            fontWeight: "bold",
            marginBottom: "20px",
            color: "#333",
        },
    };

    const imageSources = [ Image2, Image3, Image4];

    return (
        <div>
            <h1 style={styles.title}>Gallery</h1>
            <div style={styles.underline}></div>
            <div style={styles.underline1}></div>
            <div style={styles.container}>
                {imageSources.map((src, index) => (
                    <img
                        key={index}
                        src={src}
                        alt={`Gallery Image ${index + 1}`}
                        style={styles.image}
                    />
                ))}
            </div>
        </div>
    );
};

export default Gallery;
