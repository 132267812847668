const Contact = () => {
    const styles = {
        container: {
            padding: "20px",
            backgroundColor: "#f8f9fa",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "100vh",
        },
        title: {
            fontSize: "32px",
            fontWeight: "bold",
            color: "#333",
            marginBottom: "20px",
        },
        underline: {
            width: "60px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        underline1: {
            width: "50px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        form: {
            backgroundColor: "#fff",
            padding: "30px",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            maxWidth: "500px",
            width: "100%",
        },
        field: {
            marginBottom: "20px",
        },
        label: {
            display: "block",
            marginBottom: "8px",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#333",
        },
        input: {
            width: "100%",
            padding: "12px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            fontSize: "14px",
        },
        textarea: {
            width: "100%",
            padding: "12px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            fontSize: "14px",
            height: "100px",
            resize: "none",
        },
        button: {
            backgroundColor: "#f79a19",
            color: "#fff",
            border: "none",
            padding: "12px 20px",
            borderRadius: "5px",
            fontSize: "16px",
            fontWeight: "bold",
            cursor: "pointer",
            width: "100%",
        },
        buttonHover: {
            backgroundColor: "#0199c2",
        },
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Contact Us</h1>
            <div style={styles.underline}></div>
            <div style={styles.underline1}></div>
            <form style={styles.form}>
                <div style={styles.field}>
                    <label style={styles.label} htmlFor="name">
                        Name
                    </label>
                    <input
                        style={styles.input}
                        type="text"
                        id="name"
                        placeholder="Enter your name"
                        required
                    />
                </div>
                <div style={styles.field}>
                    <label style={styles.label} htmlFor="email">
                        Email
                    </label>
                    <input
                        style={styles.input}
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        required
                    />
                </div>
                <div style={styles.field}>
                    <label style={styles.label} htmlFor="message">
                        Message
                    </label>
                    <textarea
                        style={styles.textarea}
                        id="message"
                        placeholder="Enter your message"
                        required
                    ></textarea>
                </div>
                <button
                    type="submit"
                    style={styles.button}
                    onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
                    onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
                >
                    Send Message
                </button>
            </form>
        </div>
    );
};

export default Contact;
