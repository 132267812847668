const Whatweoffer = () => {
    const styles = {
        container: {
            textAlign: "center",
            padding: "20px",
            backgroundColor: "#f9f9f9",
        },
        title: {
            fontSize: "28px",
            color: "#333",
            marginBottom: "10px",
            position: "relative",
        },
        underline: {
            width: "60px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        underline1: {
            width: "50px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        image: {
            maxWidth: "60%",
            height: "auto",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        },
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>What We Offer</h1>
            <div style={styles.underline}></div>
            <div style={styles.underline1}></div>
            <img
                style={styles.image}
                src="whatweoffer.png"
                alt="What we offer"
            />
        </div>
    );
};

export default Whatweoffer;
