const Careers = () => {
    const styles = {
        container: {
            padding: "20px",
            backgroundColor: "#f8f9fa",
            fontFamily: "Arial, sans-serif",
        },
        title: {
            textAlign: "center",
            fontSize: "28px",
            fontWeight: "bold",
            marginBottom: "20px",
            color: "#333",
        },
        jobList: {
            display: "flex",
            flexDirection: "column",
            gap: "15px",
        },
        underline: {
            width: "60px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        underline1: {
            width: "50px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        jobCard: {
            backgroundColor: "#fff",
            padding: "15px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        jobDetails: {
            maxWidth: "70%",
        },
        jobTitle: {
            fontSize: "18px",
            fontWeight: "bold",
            color: "#333",
        },
        jobLocation: {
            fontSize: "14px",
            color: "#666",
            margin: "5px 0",
        },
        applyButton: {
            padding: "10px 15px",
            backgroundColor: "#0199c2",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            textDecoration: "none",
        },
        applyButtonHover: {
            backgroundColor: "#017ba5",
        },
    };

    // Dummy job openings data
    const jobs = [
        {
            title: "Software Engineer",
            location: "San Francisco, CA",
        },
        {
            title: "Product Manager",
            location: "New York, NY",
        },
        {
            title: "UI/UX Designer",
            location: "Remote",
        },
        {
            title: "Data Analyst",
            location: "Seattle, WA",
        },
        {
            title: "DevOps Engineer",
            location: "Austin, TX",
        },
    ];

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Careers</h1>
            <div style={styles.underline}></div>
            <div style={styles.underline1}></div>
            <div style={styles.jobList}>
                {jobs.map((job, index) => (
                    <div key={index} style={styles.jobCard}>
                        <div style={styles.jobDetails}>
                            <h2 style={styles.jobTitle}>{job.title}</h2>
                            <p style={styles.jobLocation}>{job.location}</p>
                        </div>
                        <a
                            href="#"
                            style={styles.applyButton}
                            onMouseEnter={(e) => e.target.style.backgroundColor = styles.applyButtonHover.backgroundColor}
                            onMouseLeave={(e) => e.target.style.backgroundColor = styles.applyButton.backgroundColor}
                        >
                            Apply Now
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Careers;
