const Blog = () => {
    const styles = {
        container: {
            padding: "20px",
            backgroundColor: "#f8f9fa",
        },
        title: {
            textAlign: "center",
            fontSize: "28px",
            fontWeight: "bold",
            marginBottom: "20px",
            color: "#333",
        },
        blogGrid: {
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)", // Creates 3 columns
            gap: "20px", // Space between grid items
        },
        blogCard: {
            backgroundColor: "#fff",
            padding: "15px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
        },
        underline: {
            width: "60px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        underline1: {
            width: "50px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        image: {
            width: "100%",
            height: "150px",
            objectFit: "cover",
            borderRadius: "8px",
            marginBottom: "10px",
        },
        blogTitle: {
            fontSize: "18px",
            fontWeight: "bold",
            margin: "10px 0",
            color: "#333",
        },
        blogDescription: {
            fontSize: "14px",
            color: "#666",
            lineHeight: "1.5",
        },
    };

    // Dummy blog data
    const blogs = [
        {
            title: "Blog Post 1",
            description: "A brief description of Blog Post 1.",
            image: "blog1.jpg", // Replace with actual image URLs
        },
        {
            title: "Blog Post 2",
            description: "A brief description of Blog Post 2.",
            image: "blog2.jpg",
        },
        {
            title: "Blog Post 3",
            description: "A brief description of Blog Post 3.",
            image: "blog3.jpg",
        },
        {
            title: "Blog Post 4",
            description: "A brief description of Blog Post 4.",
            image: "blog4.jpg",
        },
        {
            title: "Blog Post 5",
            description: "A brief description of Blog Post 5.",
            image: "blog5.jpg",
        },
        {
            title: "Blog Post 6",
            description: "A brief description of Blog Post 6.",
            image: "blog6.jpg",
        },
    ];

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Blog</h1>
            <div style={styles.underline}></div>
            <div style={styles.underline1}></div>
            <div style={styles.blogGrid}>
                {blogs.map((blog, index) => (
                    <div key={index} style={styles.blogCard}>
                        <img
                            src={blog.image}
                            alt={blog.title}
                            style={styles.image}
                        />
                        <h2 style={styles.blogTitle}>{blog.title}</h2>
                        <p style={styles.blogDescription}>{blog.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Blog;
