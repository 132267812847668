const Home = () => {
    const styles = {
    
        image: {
            width: "100%",
            height: "auto",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        },
        // textOverlay: {
        //     position: "absolute",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //     color: "white",
        //     fontSize: "14px",
        //     fontWeight: "bold",
        //     textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
        //     whiteSpace: "nowrap",
        //     overflow: "hidden",
        //     animation: "revealText 5s steps(15, end) infinite", // Animation for letter-by-letter scrolling
        //     width: "100ch", // Adjust for the length of the text
        // },
        // "@keyframes revealText": {
        //     "0%": { width: "0" },
        //     "100%": { width: "100ch" },
        // },
    };

    // const addKeyframes = () => {
    //     const styleSheet = document.styleSheets[0];
    //     const revealKeyframes = `
    //         @keyframes revealText {
    //             0% { width: 0; }
    //             100% { width: 100ch; }
    //         }
    //     `;
    //     styleSheet.insertRule(revealKeyframes, styleSheet.cssRules.length);
    // };

    // addKeyframes();

    return (
        <div style={styles.container}>
            <img
                style={styles.image}
                src="Home.png"
                alt="Home Image"
            />
            {/* <div style={styles.textOverlay}>
            We understand the business impact that
Governance processes have in the data
driven world. 
            </div> */}
            
        </div>
    );
};

export default Home;
