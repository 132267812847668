import './App.css';
import Nav from "./components/Nav";
import Contact from "./components/Contact";
import Whatweoffer from "./components/Whatweoffer";
import Home from "./components/Home";
import Ourpartners from "./components/Ourpartners";
import Careers from './components/Careers';
import Gallery from './components/Gallery';
import Aboutus from './components/Aboutus';
import Blog from './components/Blog';
import OurGovernance from './components/Ourgovernance';
import Oursolutions from './components/Oursolutions';
import Footer from './components/Footer';
import Footermain from './components/Footermain'

function App() {
  return (
    <div>
      <Nav />
      <div id="home">
        <Home />
      </div>
      <div id="aboutus">
        <Aboutus />
      </div>
      <div id="ourpartners">
        <Ourpartners />
      </div>
      <div id="whatweoffer">
        <Whatweoffer />
      </div>
      <div id="ourgovernance">
        <OurGovernance />
      </div>
      <div id="oursolutions">
        <Oursolutions />
      </div>
      <div id="blog">
        <Blog />
      </div>
      <div id="gallery">
        <Gallery />
      </div>
      <div id="careers">
        <Careers />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <div id="footermain">
        <Footermain />
      </div>
      {/* <div id="footer">
        <Footer />
      </div> */}

    </div>
  );
}

export default App;
