import { useEffect } from "react";
import Collibra from "./images/Collibra.png";
import Apache from "./images/apache ranger.png";
import Pkware from "./images/pkware.png";
import Kong from "./images/kong.png";
import APIgee from "./images/apigee.png";
import Google from "./images/Google.png";
import Hive from "./images/Hive.png";
import Ovaledge from "./images/ovaledge.png";
import ServiceNow from "./images/servicenow.png";
import Snowflake from "./images/snowflake.png";
import Starburst from "./images/starburst.png";
import Swagger from "./images/swagger.png";
import Trino from "./images/Trino.png";

const OurPartners = () => {
    const styles = {
        container: {
            overflow: "hidden",
            position: "relative",
            padding: "20px",
            backgroundColor: "#f8f9fa",
            height: "auto",
            display: "flex",
            justifyContent: "center",
        },
        sliderWrapper: {
            display: "flex",
            animation: "scroll 25s linear infinite",
        },
        slider: {
            display: "flex",
        },
        image: {
            margin: "5px",
            borderRadius: "8px",
            objectFit: "cover",
        },
        underline: {
            width: "60px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        underline1: {
            width: "50px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        title: {
            textAlign: "center",
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "20px",
            color: "#333",
        },
    };

    const imageSources = [
        Collibra,
        // ServiceNow,
        Pkware,
        // Google, 
        Apache,
        Snowflake,
        // Kong,
        // Hive,
        APIgee,
        Trino,
        Ovaledge,
        Starburst,
        Swagger,
    ];

    useEffect(() => {
        const styleSheet = document.styleSheets[0];
        const keyframes = `
            @keyframes scroll {
                0% { transform: translateX(0); }
                100% { transform: translateX(-38%); }
            }
        `;
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    }, []);

    return (
        <div>
            <h1 style={styles.title}>Our Partners</h1>
            <div style={styles.underline}></div>
            <div style={styles.underline1}></div>
            <div style={styles.container}>
                <div style={styles.sliderWrapper}>
                    <div style={styles.slider}>
                        {imageSources.map((src, index) => (
                            <img
                                key={index}
                                src={src}
                                alt={`Partner ${index + 1}`}
                                style={{ ...styles.image, width: "auto", height: "100px" }}
                            />
                        ))}
                    </div>
                    {/* Duplicate for seamless scroll */}
                    <div style={styles.slider}>
                        {imageSources.map((src, index) => (
                            <img
                                key={`duplicate-${index}`}
                                src={src}
                                alt={`Partner Duplicate ${index + 1}`}
                                style={{ ...styles.image, width: "auto", height: "100px" }}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurPartners;
