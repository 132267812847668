import { useState } from "react";

const Aboutus = () => {
    const [activeContent, setActiveContent] = useState(null);

    const styles = {
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px",
            backgroundColor: "#f8f9fa",
        },
        content: {
            width: "50%",
            fontSize: "18px",
            color: "#333",
            lineHeight: "1.6",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        image: {
            width: "40%",
            height: "auto",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        },
        title: {
            fontSize: "28px",
            color: "#333",
            marginBottom: "10px",
        },
        underline: {
            width: "60px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        underline1: {
            width: "50px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        buttonContainer: {
            marginTop: "20px",
            display: "flex",
            gap: "15px",
        },
        button: {
            padding: "10px 20px",
            fontSize: "16px",
            color: "#fff",
            backgroundColor: "#0199c2",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
        },
        buttonHover: {
            backgroundColor: "#00779a",
        },
        paragraph: {
            marginTop: "20px",
            fontSize: "16px",
            color: "#555",
            textAlign: "center",
        },
    };

    const paragraphs = {
        OurVision: "To collaborate our knowledge of Governance frameworks, best-of-breed products, insights from seasoned practitioners, and real business use cases to ideate and provide compelling Data Governance and Intelligence solutions.",
        contactUs: "Reach out to us for inquiries or to discuss how we can assist in achieving your goals.",
        ourServices: "Explore our diverse range of services designed to deliver value and drive efficiency.",
    };

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <h1 style={styles.title}>About Us</h1>
                <div style={styles.underline}></div>
                <div style={styles.underline1}></div>
                <h4>WE INNOVATE SOLUTIONS TARGETING THE REAL CHALLENGES OUR CLIENTS FACE</h4>
                <p>
                    For the data-centric organization, effective Governance processes are essential to
                    improving productivity and efficiency. Lorang helps organizations to achieve these goals
                    with targeted Governance solutions to drive business value.
                </p>
                {/* Buttons Section */}
                <div style={styles.buttonContainer}>
                    <button
                        style={styles.button}
                        onClick={() => setActiveContent("OurVision")}
                        onMouseOver={(e) => (e.target.style.backgroundColor = "#0199c2")}
                        onMouseOut={(e) => (e.target.style.backgroundColor = "#f79a19")}
                    >
                        Our Vision
                    </button>
                    <button
                        style={styles.button}
                        onClick={() => setActiveContent("contactUs")}
                        onMouseOver={(e) => (e.target.style.backgroundColor = "#0199c2")}
                        onMouseOut={(e) => (e.target.style.backgroundColor = "#f79a19")}
                    >
                        Our Mission
                    </button>
                    <button
                        style={styles.button}
                        onClick={() => setActiveContent("ourServices")}
                        onMouseOver={(e) => (e.target.style.backgroundColor = "#0199c2")}
                        onMouseOut={(e) => (e.target.style.backgroundColor = "#f79a19")}
                    >
                        Values
                    </button>
                </div>
                {activeContent && <p style={styles.paragraph}>{paragraphs[activeContent]}</p>}
            </div>
            <img src="About.png" alt="About Us" style={styles.image} />
        </div>
    );
};

export default Aboutus;
