import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF,
    faTwitter,
    faInstagram,
    faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    const styles = {
        footer: {
            backgroundColor: "#0199c2",
            color: "#fff",
            padding: "40px 20px",
            textAlign: "center",
        },
        container: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            maxWidth: "1200px",
            margin: "0 auto",
        },
        column: {
            flex: "1 1 200px",
            margin: "10px",
            textAlign: "left",
        },
        logo: {
            width: "200px",
            height: "auto",
            marginBottom: "10px",
        },
        title: {
            fontSize: "18px",
            fontWeight: "bold",
            marginBottom: "10px",
        },
        link: {
            color: "#fff",
            textDecoration: "none",
            display: "block",
            marginBottom: "5px",
        },
        contactInfo: {
            lineHeight: "1.8",
        },
        socialIcons: {
            display: "flex",
            gap: "15px",
            marginTop: "10px",
        },
        socialIcon: {
            color: "#fff",
            fontSize: "18px",
            textDecoration: "none",
            padding: "10px",
            borderRadius: "50%",
            backgroundColor: "#0179a9",
        },
        // copyrightWrapper: {
        //     backgroundColor: "#fff",
        //     color: "#0199c2",
        //     textAlign: "center",
        //     padding: "10px 0",
        //     marginTop: "20px",
        // },
        copyright: {
            fontSize: "14px",
            margin: "0",
        },
    };

    return (
        <footer style={styles.footer}>
            <div style={styles.container}>
                {/* Logo Column */}
                <div style={styles.column}>
                    <img
                        src="Lorang logo with white color.png"
                        alt="Logo"
                        style={styles.logo}
                    />
                    <p>
                        For the data-centric organization, effective Governance processes are essential
                        to improving productivity and efficiency. Lorang helps organizations to achieve
                        these goals with targeted Governance solutions to drive business value.
                    </p>
                    {/* Social Media Icons */}
                    <div style={styles.socialIcons}>
                        <a
                            href="https://www.facebook.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles.socialIcon}
                        >
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a
                            href="https://www.twitter.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles.socialIcon}
                        >
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a
                            href="https://www.instagram.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles.socialIcon}
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a
                            href="https://www.linkedin.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles.socialIcon}
                        >
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                    </div>
                </div>

                {/* Quick Links Column */}
                <div style={styles.column}>
                    <h4 style={styles.title}>Quick Links</h4>
                    <a href="/home" style={styles.link}>
                        Home
                    </a>
                    <a href="/aboutus" style={styles.link}>
                        About Us
                    </a>
                    <a href="/ourpartners" style={styles.link}>
                        Our Partners
                    </a>
                    <a href="/whatweoffer" style={styles.link}>
                        What We Offer
                    </a>
                    <a href="/ourgovernance" style={styles.link}>
                        Our Governance
                    </a>
                    <a href="/oursolutions" style={styles.link}>
                        Our Solutions
                    </a>
                    <a href="/blog" style={styles.link}>
                        Blog
                    </a>
                    <a href="/gallery" style={styles.link}>
                        Gallery
                    </a>
                    <a href="/careers" style={styles.link}>
                        Careers
                    </a>
                    <a href="/contact" style={styles.link}>
                        Contact Us
                    </a>
                </div>

                {/* Services Column */}
                <div style={styles.column}>
                    <h4 style={styles.title}>Services</h4>
                    <a href="/data-governance" style={styles.link}>
                        Data Governance
                    </a>
                    <a href="/workflow-automation" style={styles.link}>
                        Workflow Automation
                    </a>
                    <a href="/api-integration" style={styles.link}>
                        API Integration
                    </a>
                </div>

                {/* Contact Info Column */}
                <div style={styles.column}>
                    <h4 style={styles.title}>Contact Info</h4>
                    <div style={styles.contactInfo}>
                        <p>Flat No. 303, 3rd Floor, Vaishnavi Nest, Dilsukhnagar, Hyderabad-500060</p>
                        <p>Phone: +1 234 567 890</p>
                        <p>Email: info@lorangtechnologies.com</p>
                    </div>
                </div>
            </div>

            {/* Copyright Section */}
            <div style={styles.copyrightWrapper}>
                <p style={styles.copyright}>
                    © 2024 Lorang Technologies Pvt Ltd. All rights reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
