const OurGovernance = () => {
    const styles = {
        container: {
            padding: "20px",
            textAlign: "center",
            backgroundColor: "#f8f9fa",
        },
        title: {
            fontSize: "28px",
            fontWeight: "bold",
            marginBottom: "10px",
            color: "#333",
        },
        underline: {
            width: "60px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        underline1: {
            width: "50px",
            height: "2px",
            backgroundColor: "#0199c2",
            margin: "10px auto",
            borderRadius: "1px",
        },
        row: {
            display: "flex",
            justifyContent: "space-around",
            gap: "10px",
            marginTop: "40px",
        },
        image: {
            width: "20%",
            height: "auto",
            borderRadius: "8px",
            marginBottom: "10px",
        },
        cardTitle: {
            fontSize: "20px",
            fontWeight: "bold",
            margin: "10px 0",
            color: "#0199c2",
        },
        cardContent: {
            fontSize: "14px",
            color: "#555",
            lineHeight: "1.6",
            textAlign: "justify", // Justify text
            maxWidth: "400px",
        },
    };

    const items = [
        {
            imgSrc: "data-security.png",
            title: "DATA GOVERNANCE",
            content: "We aim to build and maintain systems and Data that are fit for purpose. Together we will improve the quality and security of your data.",
        },
        {
            imgSrc: "database.png",
            title: "DATA LAKE GOVERNANCE",
            content: "A Data Lake efficiently holds structured and unstructured Data of any type, ranging from clickstream, social media feeds, audio/video and machine data.",
        },
        {
            imgSrc: "data.png",
            title: "DATA CATALOG",
            content: "A Data Catalog maintains an inventory of data assets through the discovery, description, and organization of datasets.",
        },
    ];

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Our Governance Expertise</h1>
            <div style={styles.underline}></div>
            <div style={styles.underline1}></div>
            
            <div style={styles.row}>
                {items.map((item, index) => (
                    <div key={index} style={styles.card}>
                        <img
                            src={item.imgSrc}
                            alt={item.title}
                            style={styles.image}
                        />
                        <h2 style={styles.cardTitle}>{item.title}</h2>
                        <p style={styles.cardContent}>{item.content}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurGovernance;
