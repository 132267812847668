// import { Link } from "react-router-dom";
// import { useState } from "react";

// const Nav = () => {
//     const [hoveredIndex, setHoveredIndex] = useState(null);
//     const [isAboutUsHovered, setIsAboutUsHovered] = useState(false);
//     const [dropdownHoveredIndex, setDropdownHoveredIndex] = useState(null);

//     const styles = {
//         navContainer: {
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             padding: "10px 20px",
//             backgroundColor: "#f8f9fa",
//             borderBottom: "1px solid #ddd",
//             position: "relative",
//         },
//         logo: {
//             height: "40px",
//             marginRight: "10px",
//         },
//         navLinks: {
//             display: "flex",
//             gap: "15px",
//             position: "relative",
//         },
//         navItem: (isHovered) => ({
//             fontSize: "16px",
//             color: isHovered ? "#0199c2" : "#f79a19",
//             textDecoration: "none",
//             cursor: "pointer",
//             fontWeight: "bold",
//             position: "relative",
//         }),
//         dropdown: {
//             position: "absolute",
//             top: "40px",
//             left: 0,
//             backgroundColor: "#fff",
//             boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
//             borderRadius: "5px",
//             padding: "10px",
//             zIndex: 10,
//         },
//         dropdownItem: (isHovered) => ({
//             fontSize: "14px",
//             color: isHovered ? "#0199c2" : "#f79a19",
//             padding: "5px 10px",
//             textDecoration: "none",
//             cursor: "pointer",
//             display: "block",
//             backgroundColor: isHovered ? "#f1f1f1" : "transparent",
//             borderRadius: "4px",
//         }),
//     };

//     const navItems = [
//         { name: "Home", path: "/" },
//         { name: "About Us", path: "/aboutus" },
//         { name: "Our Partners", path: "/ourpartners" },
//         { name: "Who We Are", path: "/whoweare" },
//         { name: "Blog", path: "/blog" },
//         { name: "Gallery", path: "/gallery" },
//         { name: "Contact Us", path: "/contact" },
//     ];

//     const aboutUsOptions = [
//         { name: "Our Solutions", path: "/oursolutions" },
//         { name: "What We Offer", path: "/whatweoffer" },
//         { name: "Careers", path: "/careers" },
//     ];

//     return (
//         <div style={styles.navContainer}>
//             <img src="Picture1.png" alt="Logo" style={styles.logo} />
//             <div style={styles.navLinks}>
//                 {navItems.map((item, index) => (
//                     <div
//                         key={index}
//                         style={{ position: "relative" }}
//                         onMouseEnter={() => {
//                             setHoveredIndex(index);
//                             if (item.name === "About Us") setIsAboutUsHovered(true);
//                         }}
//                         onMouseLeave={() => {
//                             setHoveredIndex(null);
//                             if (item.name === "About Us") setIsAboutUsHovered(false);
//                         }}
//                     >
//                         <Link to={item.path} style={styles.navItem(index === hoveredIndex)}>
//                             {item.name}
//                         </Link>
//                         {item.name === "About Us" && isAboutUsHovered && (
//                             <div style={styles.dropdown}>
//                                 {aboutUsOptions.map((option, idx) => (
//                                     <Link
//                                         key={idx}
//                                         to={option.path}
//                                         style={styles.dropdownItem(
//                                             idx === dropdownHoveredIndex
//                                         )}
//                                         onMouseEnter={() => setDropdownHoveredIndex(idx)}
//                                         onMouseLeave={() => setDropdownHoveredIndex(null)}
//                                     >
//                                         {option.name}
//                                     </Link>
//                                 ))}
//                             </div>
//                         )}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default Nav;
import { useState } from "react";

const Nav = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const styles = {
        navContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 20px",
            backgroundColor: "#f8f9fa",
            borderBottom: "1px solid #ddd",
            position: "sticky",
            top: 0,
            zIndex: 1000,
        },
        logo: {
            height: "40px",
            marginRight: "10px",
        },
        navLinks: {
            display: "flex",
            gap: "15px",
        },
        navItem: (isHovered) => ({
            fontSize: "16px",
            color: isHovered ? "#0199c2" : "#f79a19",
            textDecoration: "none",
            cursor: "pointer",
            fontWeight: "bold",
        }),
    };

    const navItems = [
        { name: "Home", id: "home" },
        { name: "About Us", id: "aboutus" },
        { name: "Our Partners", id: "ourpartners" },
        { name: "What We Offer", id: "whatweoffer" },
        { name: "Our Governance", id: "ourgovernance" },
        { name: "Our Solutions", id: "oursolutions" },
        { name: "Blog", id: "blog" },
        { name: "Gallery", id: "gallery" },
        { name: "Careers", id: "careers" },
        { name: "Contact Us", id: "contact" },
    ];

    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div style={styles.navContainer}>
            <img src="Picture1.png" alt="Logo" style={styles.logo} />
            <div style={styles.navLinks}>
                {navItems.map((item, index) => (
                    <div
                        key={index}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        onClick={() => handleScroll(item.id)}
                        style={styles.navItem(index === hoveredIndex)}
                    >
                        {item.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Nav;
